import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'app',
  initialState: {
    error: null,
    bottomSheetOpen: false,
    multipliers: {
      population_norm: 0.3,
      competitors_count_norm: 1.5,
      ppow_mean_norm: 0.5,
      alfred24_count_norm: -4,
      shopping_mall_norm: 0.3,
      parking_norm: 0.2,
      bus_station_norm: 0.2,
      visits_mean_norm: 1,
    },
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setBottomSheetOpen: (state, action) => {
      state.bottomSheetOpen = action.payload;
    },
    setMultipliers: (state, action) => {
      state.multipliers = action.payload;
    },
  },
});

export default slice.reducer;

export const setError = (payload) => ({ type: 'app/setError', payload });
export const setBottomSheetOpen = (payload) => ({
  type: 'app/setBottomSheetOpen',
  payload,
});
export const setMultipliers = (payload) => ({ type: 'app/setMultipliers', payload });
